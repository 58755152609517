html {
  scroll-snap-type: y mandatory;
}

.mainpage__wrapper {
  background-image: url('./bulb.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.scroll-child {
  scroll-snap-align: start;

  /* height: 100vh; */
  /* width: 100vw; */
}
