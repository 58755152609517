.contact__wrapper {
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center; 
}

.contact__wrapper span, .contact__wrapper a {
    margin: 5px;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    text-align: right;
}